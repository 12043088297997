import styled from "styled-components";

const Nav = styled.nav`
  position: relative;
  position: fixed;
  left: 0;
  z-index: 1;
  width: 100%;
  transition: top, background;
  transition-duration: 120ms;
  transition-timing-function: ease-in-out;
  top: ${props => (props.visible ? "0" : "-150px")};
  border-bottom: ${props =>
    props.theme === "dark" ? "1px solid #F2F2F2;" : "none"};
  background: ${props => {
    if (props.theme === "light" && !props.visible) {
      return "#FE632B";
    }

    if (props.theme === "light") {
      return "transparent";
    } else {
      return "#F4FAFF";
    }
  }};

  @media (max-width: 1023px) {
    top: ${props => (props.visible ? "0" : "0")};
  }
`;

export default Nav;
