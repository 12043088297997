import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: ${props => (props.marginTopForNav ? "207px" : "0")};
  padding: 0 30px;

  @media (max-width: 1077px) {
    width: auto;
    padding: ${props => (props.nopaddingmobile ? "0" : "0 30px;")};
  }
`;

export default Container;
