// Local
// export const BASE_URL = "http://127.0.0.1:8000";
// export const ADMIN_URL = "http://127.0.0.1:8000/api/v1";
// export const API_IMAGE_PATH = 'http://127.0.0.1:8000/storage/';

//new stage
export const BASE_URL = "https://admin.my2morrow.stageoptimal.co.za";
export const ADMIN_URL = "https://admin.my2morrow.stageoptimal.co.za/api/v1";
export const API_IMAGE_PATH = 'https://admin.my2morrow.stageoptimal.co.za/storage/';

//old stage (live as well)
// export const BASE_URL = "https://mtm-cms.stg7.optimalonline.co.za.co.za";
// export const ADMIN_URL = "https://mtm-cms.stg7.optimalonline.co.za/api/v1";
// export const API_IMAGE_PATH = 'https://mtm-cms.stg7.optimalonline.co.za/storage/';

// future live
// export const BASE_URL = "https://admin.my2morrow.com";
// export const ADMIN_URL = "https://admin.my2morrow.com/api/v1";
// export const API_IMAGE_PATH = 'https://admin.my2morrow.com/storage/';
