import { API_IMAGE_PATH } from "../paths";
import { HOST, MERCHANT_ID, MERCHANT_KEY } from "../config";
import { ADMIN_URL, BASE_URL } from "../paths";
import _ from "lodash";

export const waitForResources = resource => {
  if (_.isEmpty(resource)) {
    return false;
  } else {
    return true;
  }
};

export const toLowerCase = string => {
  return string.toLowerCase();
};

export const apiImagePath = image => {
  return API_IMAGE_PATH + JSON.parse(image.replace(/\\/g, "/"));
};

export const deviceImageSwap = (mobileImage, desktopImage) => {
  return window.innerWidth < 768 && mobileImage ? mobileImage : desktopImage;
};

export const textTruncate = function(str, length, ending) {
  if (length == null) {
    length = 100;
  }

  if (ending == null) {
    ending = "...";
  }

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const syllabify = words => {
  const syllableRegex = /[^aeiouy]*[aeiouy]+(?:[^aeiouy]*$|[^aeiouy](?=[^aeiouy]))?/gi;
  return words.match(syllableRegex);
};

export const getQueryString = (field, url) => {
  var href = url ? url : window.location.href;
  var reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
  var string = reg.exec(href);
  return string ? string[1] : null;
};

//Append or edit url parameters
export const setParam = (uri, key, val) => {
  return uri
    .replace(
      RegExp("([?&]" + key + "(?=[=&#]|$)[^#&]*|(?=#|$))"),
      "&" + key + "=" + encodeURIComponent(val)
    )
    .replace(/^([^?&]+)&/, "$1?");
};

export const removeParam = (key, sourceURL) => {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
};

export const chunk = (arr, n) => {
  return arr.reduce(function(p, cur, i) {
    (p[(i / n) | 0] = p[(i / n) | 0] || []).push(cur);
    return p;
  }, []);
};

export const downloadFile = filePath => {
  var link = document.createElement("a");
  link.href = filePath;
  link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
  link.click();
};

export const downloadPDF = pdf => {
  const linkSource = `data:application/pdf;base64,${pdf}`;
  const downloadLink = document.createElement("a");
  const fileName = "planning-for-success";

  downloadLink.href = pdf;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const buildPayfastQuery = query => {
  const host = `${HOST}/eng/process`;
  const cancelURL = encodeURI(
    BASE_URL + `${query.cancelURL}?tokens=${JSON.stringify(query.tokens)}`
  );
  const returnURL = encodeURI(BASE_URL + query.returnURL);
  const notifyURL = encodeURI(
    ADMIN_URL + `/payfast-notify?tokens=${JSON.stringify(query.tokens)}`
  );

  const queryVars = {
    merchant_id: MERCHANT_ID,
    merchant_key: MERCHANT_KEY,
    amount: query.amount,
    item_name: query.itemName,
    name_first: query.userDetails.first_name,
    name_last: query.userDetails.surname,
    email_address: query.userDetails.email_address,
    return_url: returnURL,
    cancel_url: cancelURL,
    notify_url: notifyURL
  };

  return `${host}?${buildURLQuery(queryVars)}`;
};

export const buildURLQuery = obj =>
  Object.entries(obj)
    .map(pair => pair.map(encodeURIComponent).join("="))
    .join("&");

export const fetchLocalStorage = type => {
  return JSON.parse(localStorage.getItem(type));
};
