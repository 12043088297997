import "./loader.scss";
import React, { Component } from "react";

class Loader extends Component {
  renderLoader() {
    const { fullscreen, halfscreen } = this.props;
    const classNames = [
      "loader",
      fullscreen && "fullscreen",
      halfscreen && "halfscreen"
    ];
    return (
      <div className={classNames.join(" ")}>
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderLoader();
  }
}

export default Loader;
