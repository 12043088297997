import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ButtonDefault = styled.button`
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;

const ButtonLink = styled(Link)`
  display: ${props => (props.hideonmobile ? "none" : "block")};
  border-radius: 30px;
  color: #fff;
  background-color: ${props =>
    props.theme === "secondary" ? "#14B3EB" : "#35375B"};
  margin-top: ${props => (props.margintop ? "30px" : "auto")};
  margin-bottom: ${props => (props.marginbottom ? "30px" : "auto")};
  padding: 13px 30px;
  font-weight: 700;
  font-size: 14px;
  border: 2px solid transparent;
  transition: all 150ms;
  text-decoration: none;
  &:hover {
    background: transparent;
    border: 2px solid;
    ${props => (props.theme === "secondary" ? "#14B3EB" : "#35375B")};
    color: ${props => (props.theme === "secondary" ? "#14B3EB" : "#35375B")};
  }
`;

const ButtonSubmit = styled(ButtonDefault)`
  display: block;
  border-radius: 30px;
  color: #fff;
  background-color: ${props =>
    props.theme === "secondary" ? "#14B3EB" : "#35375B"};
  margin-top: ${props => (props.margintop ? "30px" : "auto")};
  margin-bottom: ${props => (props.marginbottom ? "30px" : "auto")};
  padding: 13px 30px;
  font-weight: 700;
  font-size: 14px;
  border: 2px solid transparent;
  transition: all 150ms;
  text-decoration: none;
  &:hover {
    background: transparent;
    border: 2px solid;
    ${props => (props.theme === "secondary" ? "#14B3EB" : "#35375B")};
    color: ${props => (props.theme === "secondary" ? "#14B3EB" : "#35375B")};
  }
`;

const ButtonPDFView = styled.a`
  display: block;
  border-radius: 30px;
  color: #fff;
  background-color: ${props =>
    props.theme === "secondary" ? "#14B3EB" : "#35375B"};
  margin-top: ${props => (props.margintop ? "30px" : "0")};
  margin-bottom: ${props => (props.marginbottom ? "30px" : "auto")};
  padding: 13px 30px;
  font-weight: 700;
  font-size: 14px;
  border: 2px solid transparent;
  transition: all 150ms;
  text-decoration: none;
  &:hover {
    background: transparent;
    border: 2px solid;
    ${props => (props.theme === "secondary" ? "#14B3EB" : "#35375B")};
    color: ${props => (props.theme === "secondary" ? "#14B3EB" : "#35375B")};
  }
`;

function Button(props) {
  switch (props.type) {
    case "redirect":
      return (
        <ButtonDefault>
          <ButtonLink {...props}>{props.children}</ButtonLink>
        </ButtonDefault>
      );

    case "download":
      return (
        <ButtonSubmit onClick={props.onClick}>{props.children}</ButtonSubmit>
      );

    case "viewPDF":
      return (
        <ButtonPDFView target="_blank" {...props}>
          {props.children}
        </ButtonPDFView>
      );

    case "submit":
      return <ButtonSubmit>{props.children}</ButtonSubmit>;

    default:
      return (
        <ButtonDefault>
          <ButtonLink {...props}>{props.children}</ButtonLink>
        </ButtonDefault>
      );
  }
}

export default Button;
