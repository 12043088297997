import _ from "lodash";
import React, { Fragment } from "react";
import Section from "../ui/section/Section";
import SectionHeader from "../ui/section/SectionHeader";
import Heading from "../ui/text/Heading";
import ContainerForm from "../ui/containers/ContainerForm";
import Input from "../ui/forms/inputs/Input";
import FormGroup from "../ui/forms/layouts/FormGroup";
import Error from "../ui/forms/layouts/Error";
import Grid from "../ui/grid/Grid";
import GridItem from "../ui/grid/GridItem";
import Button from "../ui/buttons/Button";
import mytomorrow from "../../api/mytomorrow";
import history from "../../utils/history/history";
import scrollToFirstError from '../ui/forms/SmoothScrollToErrorFields.js';

const careerCounselling = [
  "Subject Choice Assessment for Teenagers",
  "Career Counselling for High-School Learners and Young Adults",
  "Career Change Counselling for Adults"
];

const situationOptions = [
  "I am clueless and have had no career guidance yet",
  "I have had some career guidance, but suffer from information overload | still feel unsure",
  "I have made a career decision, but I have some concerns"
];

const describeYouOptions = [
  "I am in the last two years of high school",
  "I am graduating from school this year",
  "I took a gap year after school, but I am still undecided",
  "I chose the wrong study field and feel stuck!",
  "I have a job, but not a great job and need to change to keep my sanity"
];

const previouslyCompletedOptions = ["Yes", "No"];

class StartAConversation extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      location: "",
      email: "",
      mobile: "",
      currentSituation: "",
      describeYou: "",
      previouslyCompleted: "",
      careerCounselling: "",
      errors: {}
    };
  }

  renderCheckboxes = (items, name) => {
    return items.map((item, i) => {
      return (
        <FormGroup>
          <Input
            key={i}
            type="radio"
            name={name}
            value={item}
            onChange={this.handleChange}
          />
          <label>{item}</label>
        </FormGroup>
      );
    });
  };

  renderError = err => {
    if (this.state.errors[err]) {
      return <Error>{this.state.errors[err]}</Error>;
    }
  };

  handleChange = event => {
    let errors = this.state.errors;
    if (event.target.value !== "") {
      delete errors[event.target.name];
    }

    this.setState({
      [event.target.name]: event.target.value,
      errors
    });
  };

  validate = formValues => {
    return new Promise((resolve, reject) => {
      let errors = {};

      if (formValues.fullName === "") {
        errors.fullName = "Please enter your full name";
      }

      if (formValues.location === "") {
        errors.location = "Please enter your location";
      }

      if (formValues.email === "") {
        errors.email = "Please enter your email";
      }

      if (formValues.mobile === "") {
        errors.mobile = "Please enter your mobile number";
      }

      this.setState({ errors });
      resolve(resolve);
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    let formValues = { ...this.state };
    await this.validate(formValues);

    if (_.isEmpty(this.state.errors)) {
      try {
        const response = mytomorrow
          .post("general-enquiry", formValues)
          .then(res => {
            res.data === "success" && history.push("thank-you");
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      scrollToFirstError(this.state.errors);
    }
  };

  render() {
    return (
      <ContainerForm marginTopForNav>
        <Section>
          <SectionHeader>
            <Heading as="h2" centered primary>
              START A CONVERSATION
            </Heading>
          </SectionHeader>

          <form onSubmit={this.handleSubmit}>
            <Grid>
              <GridItem sizes={["col-md-6"]}>
                <FormGroup>
                  {'fullName' && <div name={`position-fullName`} />}
                  <Input
                    type="text"
                    name="fullName"
                    value={this.state.value}
                    onChange={this.handleChange}
                    placeholder="Name and Surname | Last Name"
                  />
                  {this.renderError("fullName")}
                </FormGroup>
              </GridItem>

              <GridItem sizes={["col-md-6"]}>
                <FormGroup>
                  {'location' && <div name={`position-location`} />}
                  <Input
                    type="text"
                    name="location"
                    value={this.state.value}
                    onChange={this.handleChange}
                    placeholder="Location (e.g. Cape Town, South Africa)"
                  />
                  {this.renderError("location")}
                </FormGroup>
              </GridItem>
            </Grid>

            <Grid>
              <GridItem sizes={["col-md-6"]}>
                <FormGroup>
                  {'email' && <div name={`position-email`} />}
                  <Input
                    type="text"
                    name="email"
                    value={this.state.value}
                    onChange={this.handleChange}
                    placeholder="E-mail Address"
                  />
                  {this.renderError("email")}
                </FormGroup>
              </GridItem>

              <GridItem sizes={["col-md-6"]}>
                <FormGroup>
                  {'mobile' && <div name={`position-mobile`} />}
                  <Input
                    type="text"
                    name="mobile"
                    value={this.state.value}
                    onChange={this.handleChange}
                    placeholder="Mobile number (Country Code) (Number)"
                  />
                  {this.renderError("mobile")}
                </FormGroup>
              </GridItem>
            </Grid>

            <br />
            <br />
            <br />

            <Grid>
              <GridItem sizes={["col-md-12"]}>
                <Heading primary as="h4">
                What type of Career Counselling do you require?
                </Heading>
                {this.renderCheckboxes(careerCounselling, "careerCounselling")}
              </GridItem>
            </Grid>

            {/* <Grid>
              <GridItem sizes={["col-md-12"]}>
                <Heading primary as="h4">
                  Which of the following statements describe your current
                  situation best?
                </Heading>
                {this.renderCheckboxes(situationOptions, "currentSituation")}
              </GridItem>
            </Grid>

            <br />
            <br />
            <br />

            <Grid>
              <GridItem sizes={["col-md-12"]}>
                <Heading primary as="h4">
                  Which of the following statements describe you best?
                </Heading>
                {this.renderCheckboxes(describeYouOptions, "describeYou")}
              </GridItem>
            </Grid>

            <br />
            <br />
            <br />

            <Grid>
              <GridItem sizes={["col-md-12"]}>
                <Heading primary as="h4">
                  Have you previously completed assessments or questionnaires
                  relating to career guidance | choices?
                </Heading>
                {this.renderCheckboxes(
                  previouslyCompletedOptions,
                  "previouslyCompleted"
                )}
              </GridItem>
            </Grid> */}

            <br />
            <br />
            <br />

            <Button type="submit">SUBMIT</Button>
          </form>
        </Section>
      </ContainerForm>
    );
  }
}

export default StartAConversation;
