import _ from "lodash";
import React, { Fragment } from "react";
import useResources from "../resources/useResources";
import GettingStarted from "../modules/GettingStarted";
import PageModules from "../modules/PageModules";
import NavSpacer from "../ui/structure/NavSpacer";

export default function AboutUs({ resource }) {
  window.scrollTo(0, 0);
  const page = useResources(resource);

  return (
    <Fragment>
      <NavSpacer />
      <PageModules page={page} />
      <GettingStarted />
    </Fragment>
  );
}
