import { useState, useEffect } from "react";
import mytomorrow from "../../api/mytomorrow";

const useResources = resource => {
  const [resources, setResources] = useState([]);

  useEffect(() => {
    (async resource => {
      const response = await mytomorrow.get(`/${resource}`);

      setResources(response.data);
    })(resource);
  }, [resource]);

  return resources;
};

export default useResources;
