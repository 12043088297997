import styled from "styled-components";

const Input = styled.input`
  padding: 12px 10px;
  width: ${props => (props.type === "text" ? "100%" : "auto")};
  margin-right: ${props => (props.type === "radio" ? "10px" : "0")};
  border-radius: 5px;
  border: 1px solid #eaeaea;
`;

export default Input;
